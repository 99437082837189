<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item
          v-for="item in sitemapLinks"
          :key="item.type"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.type }}</v-list-item-title>
            <v-list-item-subtitle>
              <a target="_blank" :href="item.link">
                {{item.link}}
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  metaInfo() {
    return {
      title: 'Sitemap'
    }
  },
  data: () => ({
    content: '',
  }),
  computed: {
    sitemapLinks() {
      return this.sitemapTypes.map(type => ({
        type,
        link: `${this.eagleEnv.apiEndPoint}/sitemap/${type}`,
      }))
    },
    sitemapTypes() {
      return this.siteConfig.sitemap.types
    },
    ...mapGetters({
      siteConfig: 'base/siteConfig',
      eagleEnv: 'base/eagleEnv',
    }),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
